import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import HeaderMeta from '../components/HeaderMeta';

import {
  ResponsiveMaxWidthFrame,
  SectionGroup,
  PageNameBox,
  TitleBox,
  SubHeadlineBox,
} from "@minware/ui-minware";
import LinkType from '../components/LinkType';
import { track } from '../analytics';

const ServiceUnavailablePage = ({ location }) => {
  const header = {
    title: `Service Unavailable | minware`,
    description: `The requested page is unavailable at this time`,
  }

  const params = new URLSearchParams(location.search);
  const originalUrl = params.get('originalUrl');
  const statusCode = params.get('statusCode') || '5XX';

  useEffect(() => {
    track('service-unavailable', {
      originalUrl,
      statusCode,
    });
  }, [originalUrl, statusCode]);

  return (
    <Layout desktopBgImageName="none">
      <HeaderMeta header={header}/>
      <div>
        <ResponsiveMaxWidthFrame maxWidth="500px">
          <SectionGroup>
            <PageNameBox>Service Unavailable</PageNameBox>
            <TitleBox>
              The page you requested was unavailable at this time.
            </TitleBox>
            <SubHeadlineBox centered={true}>
              Please try loading the page again, or contact support@minware.com if problems continue.
            </SubHeadlineBox>
          </SectionGroup>
        </ResponsiveMaxWidthFrame>
      </div>
    </Layout>
  );
}

export default ServiceUnavailablePage
